* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100vh;
}

html {
  font-size: 22px;
  color: black;
  scroll-behavior: smooth;
}

* {
  font-family: AlbertSans, sans-serif;
}

@font-face {
  font-family: "AlbertSans";
  src: url("../public/fonts/AlbertSans/AlbertSans-VariableFont_wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AlbertSansExtraBold";
  src: url("../public/fonts/AlbertSans/static/AlbertSans-ExtraBold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AlbertSansThin";
  src: url("../public/fonts/AlbertSans/static/AlbertSans-ExtraLight.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArchitectsDaughter";
  src: url("../public/fonts/ArchitectsDaughter/ArchitectsDaughter-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

section {
  width: 100%;
  height: fit-content;
}

.layout {
  display: flex;
  height: 100%;
}

.side_panel {
  width: 40%;
  background-color: #fbfbfb;
  border-right: 2px solid #e6e6e6;
  padding: 30px;
}

.left_side_content {
  width: 40%;
}

.side_panel_content {
  margin-top: 60px;
}

.main_title {
  font-size: 14pt;
  margin-bottom: 20px;
}

.side_panel_title {
  font-size: 25pt;
  font-weight: bold;
  color: #808080;
  margin-bottom: 20px;
}

.side_panel_description {
  font-size: 11pt;
  color: #808080;
  margin-bottom: 20px;
}

.secondary_action_btn {
  border: 2px solid #f8a392;
  color: #f24727;
  border-radius: 5px;
  height: 50px;
  background-color: white;
  width: 120px;
  cursor: pointer;
}

.primary_action_btn {
  color: white;
  border-radius: 5px;
  height: 50px;
  background-color: #f24727;
  width: 120px;
  border: none;
  cursor: pointer;
}

.content {
  width: 60%;
  background-color: white;
  padding: 30px;
  display: flex;
  align-items: center;
}

input,
input:focus-visible {
  border-radius: 3px;
  box-shadow: inset 0 0 6px #dfdfdf;
  border: 1px solid #c3cad6;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 15px;
  width: 90%;
}

textarea,
textarea:focus-visible {
  border-radius: 3px;
  box-shadow: inset 0 0 6px #dfdfdf;
  border: 1px solid #c3cad6;
  outline: none;
  resize: none;
}

.auth_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.error_message {
  color: #da2b0c;
  font-size: 9pt;
  padding-bottom: 10px;
}

.pa-30 {
  padding: 30px;
}

.bg-grey {
  background-color: #fbfbfb;
}

.payment_options_blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.payment_option {
  width: 30%;
  border: 2px solid #c1c1c1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.payment_option_title {
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 10px;
}

.payment_option_description {
  font-size: 11pt;
  color: #808080;
  margin-bottom: 20px;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
  font-size: 14px;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #acacac;
  border-radius: 8px;
  border: 2px solid transparent;
}

input[type="checkbox"] {
  display: none;
}

label.toggle {
  display: inline-block;
  width: 30px;
  height: 18px;
  background-color: transparent;
  border: 1px solid #c9c9c9;
  border-radius: 13px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

input[type="checkbox"]:checked + label.toggle {
  background-color: transparent;
  border: 1px solid #66952a;
}

input[type="radio"] {
  box-shadow: none;
}

label.toggle::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  background-color: #c9c9c9;
  border-radius: 50%;
  transition: left 0.3s;
}

input[type="checkbox"]:checked + label.toggle::after {
  left: 13px;
  background-color: #66952a;
}

.custom-tooltip {
  background-color: #ffffff !important;
  border-radius: 6px !important;
  border: 1px solid #ededed !important;
  color: black !important;
  font-size: 12px !important;
  font-family: "AlbertSans", sans-serif !important;
  font-weight: 400 !important;
  box-shadow: 0 4 20px #010101 !important;
  z-index: 99999;
  opacity: 1 !important;

  &.ws-pre-wrap {
    white-space: pre-wrap;
  }

  &.react-tooltip__place-right {
    .react-tooltip-arrow {
      border-left: 1px solid #ededed !important;
      border-bottom: 1px solid #ededed !important;
    }
  }

  &.react-tooltip__place-left {
    .react-tooltip-arrow {
      border-right: 1px solid #ededed !important;
      border-top: 1px solid #ededed !important;
    }
  }

  &.react-tooltip__place-bottom {
    .react-tooltip-arrow {
      border-left: 1px solid #ededed !important;
      border-top: 1px solid #ededed !important;
    }
  }

  &.react-tooltip__place-top {
    .react-tooltip-arrow {
      border-right: 1px solid #ededed !important;
      border-bottom: 1px solid #ededed !important;
    }
  }

  &.custom-tooltip-poppins {
    font-family: "Poppins", sans-serif;
    line-height: 17px;
  }

  &.custom-tooltip-question {
    width: 250px;
    max-width: 90vw;
  }
}

.custom-tooltip_custom-width {
  width: fit-content !important;
}

.multi-select {
  cursor: pointer;
  height: 38px;
  width: 208px;
  border-radius: 3px;
  font-size: 12px;
}

.popup-open {
  overflow: hidden;
}

#outreach-extension-warning-message {
  position: absolute;
  top: 20px;
  left: calc((100vw - 400px) / 2);
  width: 400px;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(
    to right,
    #ffe6a6,
    #ffe2a6,
    #ffdda6,
    #ffd9a6,
    #ffd5a7
  );
  border-radius: 7px;
  border: 1px solid #f9d2ae;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  z-index: 999999;
}

#outreach-extension-warning-message.slide-in {
  animation-name: slideIn;
}

#outreach-extension-warning-message.slide-out {
  animation-name: slideOut;
}

#outreach-extension-warning-message-icon {
  align-self: center;
  width: 22px;
  margin: 0 15px;
}

#outreach-extension-warning-message-title {
  font-family: "AlbertSans", sans-serif;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
}

#outreach-extension-warning-message-description {
  font-family: "AlbertSans", sans-serif;
  font-size: 12px;
  margin-bottom: 15px;
  line-height: 15px;
}

@keyframes slideIn {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-150%);
  }
}

.notification-empty-animation {
  animation: emptyEffect 1s forwards;
}

@keyframes emptyEffect {
  0% {
    transform: translate(-2px, 0) scaleX(1);
  }
  33% {
    transform: translate(0, 0) scaleX(1);
  }
  66% {
    transform: translate(-2px, 0) scaleX(1);
  }
  100% {
    transform: translate(0, 0) scaleX(1);
  }
}
